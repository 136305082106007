import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevShouldAvgust: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Darkness of the Abyss</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_abyss.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Darkness of the Abyss</h1>
          <h2>
            Everything you need to know about the Darkness of the Abyss Raid!
          </h2>
          <p>
            Last updated: <strong>19/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <StaticImage
        src="../../../images/reverse/generic/boss_3.webp"
        alt="Raid Boss"
      />
      <p>
        This cute little fellow is <strong>the Darkness of the Abyss</strong>{' '}
        (henceforth we’ll call it “Gorgon”), and it serves as the boss that
        spearheads the Mane’s Bulletin gamemode. Its Afflatus is Mineral, so you
        are advised to utilize Star oriented teams while dealing with it;
        specifically 37 is good against this boss, and we’ll go into why.
      </p>
      <p>
        When it spawns it’ll spawn to “Tentacles of the Abyss” alongside it
        These will be important for an upcoming mechanic. You may also notice
        that the boss has a [Eureka] bar instead of a Moxie bar; this will serve
        as your primary indicator of what kind of mechanics Gorgon will perform.
      </p>
      <ul>
        <li>
          In this fight, <strong>Star characters gain +20%</strong> to their
          stats.
        </li>
        <li>
          <strong>Gorgon passively takes +75% DMG from Genesis</strong>. This
          makes 37 an extremely appealing unit to run as Carry, and some even
          consider her the only unit that can directly attack Gorgon. Instead,
          most other characters will be relegated to dealing with the Tentacles
          as killing those deals damage to Gorgon itself.
        </li>
        <li>
          <strong>
            At the end of a round, Gorgon cleanses itself of all debuffs
          </strong>
          . Initially you may have considered using a Plant team with Jessica
          and Sotheby for the Genesis DMG from [Poison], but they lose viability
          due to this gimmick.
        </li>
        <ul>
          <li>
            In addition, if Gorgon goes undamaged for a round, it applies a
            layer of [Sturdiness] to itself, so{' '}
            <strong>make sure to at least tickle it every round </strong>
            especially if you’re trying to set up for damage.
          </li>
        </ul>
        <li>
          <strong>[Frostbite]</strong> is the main mechanic for this fight.{' '}
        </li>
        <ul>
          <li>
            Every 2 rounds, Gorgon targets the two characters with the highest
            percentage of HP left and inflicts 2 stacks of [Frostbite] on them.
            When this reaches 5 stacks, the target is inflicted with [Freeze]
            and takes massive damage when [Freeze] ends.
          </li>
          <li>
            The Gorgon’s Ultimate will inflict 5 stacks of [Frostbite]
            immediately.
          </li>
          <li>
            [Frostbite] stacks can be removed by taking active actions twice in
            one round.{' '}
          </li>
        </ul>
        <li>
          The Tentacles will also occasionally inflict [Frostbite], so
          temporarily taking down one of the two Tentacles will help mitigate
          the danger of [Freeze].
        </li>
        <li>
          <strong>
            When Gorgon reaches max [Eureka] it will enter into a special shield
            phase
          </strong>
          . If the shield isn’t taken down within 3 rounds, Gorgon will dispel
          all buffs on your team before hitting them with an AoE nuke and
          inflicting [Freeze]. This sequence deals a dreadful amount of damage,
          so it’s advised to break the shield over trying to survive this
          mechanic.
        </li>
        <li>
          In Endless Mode, after casting their Ultimates, Gorgon/Tentacles gain
          an undispellable buff that reduces incoming damage, so it is
          imperative that you either time your damage correctly or prevent a
          majority of the Ultimates from going out utilizing things like
          [Silence] or Moxie reduction.
        </li>
        <ul>
          <li>
            The Tentacles may also apply a layer of this buff on themselves and
            the boss with one of their skills.
          </li>
        </ul>
        <li>
          Also in Endless Mode, 4 stacks of [Frostbite] will be applied per
          attack instead of 2.
        </li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <p>
        In terms of dealing with this fight, you have a few options when it
        comes to team composition. Usually you’ll be running a Star oriented
        team, with some unique picks here and there. Consider the following
        compositions:
      </p>
      <div className="team-box">
        <h5 className="star">STAR TEAM</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="37"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="lilya"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="charlie"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="lilya"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="regulus"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="37"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="voyager"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="baby-blue"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="ms-newbabel"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>Any other healer.</p>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            This is a full Star team, drawing utmost use out of the Afflatus
            advantage against Mineral. It also features 37 and Voyager, both of
            which have major or continuous sources of Genesis DMG to exploit the
            +75% Genesis DMG Taken passive of Gorgon.
          </p>
          <ul>
            <li>
              37 is naturally there to provide strong Genesis DMG as well as a
              bit of offensive coverage using her Ultimate “Realm of Numbers and
              Geometry” to apply stacks of +DMG Dealt to all allies. Due to
              Gorgon taking increased Genesis DMG, 37 is the only character that
              can reliable deal damage
            </li>
            <li>
              Depending on your Portrays on 37, you will be using Lilya as your
              Main Carry (if you have 37 at P0-1) or as a Support (if you have
              37 at P2+). The reason for this being that Lilya’s “Crosswind
              Takeoff” skill has an Extra Action feature, which helps 37 stack
              her [Eureka] to trigger the [Supplementary Formula] attack.
            </li>
            <li>
              Contrary to some belief, Voyager’s [Counter] effect sadly does not
              count as an Extra Action, and therefore does not help 37 stack up
              [Eureka]. However, Voyager does inflict consistent 2-target
              [Confusion] and applies [Sturdiness], which helps with triggering
              crits as well as keeping up the defense as you run late into
              Endless mode.
            </li>
            <li>
              Tooth Fairy is the obvious pick for a Healer here. Absurd
              Crit-based debuffs, the uptime of which can be basically permanent
              due to the added AP (at P2) from 4-man content. Powerful healing
              with “Lullaby”, and decent single-target damage and some cleansing
              using “Song for the Bad Tooth”.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Charlie</strong> - Due to the additional team slot being
              able to dedicate itself to another Crit-oriented Support character
              like Voyager, Twins Sleep (or Ezra Theodore by 1.5) it is a lot
              easier to make Charlie crit despite her disastrous Crit Tech.
            </li>
            <li>
              <strong>Regulus</strong> - Given that Regulus is a defensive
              damage dealer with access to crit conversion, she’d naturally fit
              in well as fulfilling both the Carry and Support role. Moxie
              reduction works on the Tentacle mobs and [Riot and Roll] is one of
              the best defensive buffs when you’re knee-deep in an Endless run.
            </li>
            <li>
              <strong>Baby Blue</strong> - A more niche pick for a Support but
              potent if you’re running something like 37 / Regulus / Tooth
              Fairy. The extra AP allows you to set up debuffs more easily with
              Baby Blue without impeding on your Carry.
            </li>
            <li>
              <strong>6</strong> - While not available until the second phase of
              the patch, 6 on his own is a staple Support especially for Mane’s
              Bulletin. He can be used in pretty much every team due to the
              versatility of his buffs, debuffs and access to semi-on-demand
              [Incantation Empowerment] for your Carry.
            </li>
            <li>
              <strong>Ms NewBabel</strong> - Finds a spot in 37 teams,
              especially in the Gorgon fight specifically. Gorgon and its mobs
              deal Reality DMG, something that NewBabel excels at protecting
              your team against. Unlike Voyager’s [Counter], NewBabel’s Ultimate
              does count as an Extra Action, allowing you to quickly charge 37’s
              [Eureka].
            </li>
            <li>
              <strong>Healers</strong> - As a rule of thumb, very few healers
              are restricted to particular teams, as their main role is to keep
              the team alive. It is also not uncommon for Endless teams to
              feature 2 healers, one occupying a Support slot.
            </li>
          </ul>
        </div>
      </div>
      <div className="team-box">
        <h5 className="beast">BEAST / MINERAL TEAM</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="melania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kaalaa-baunaa"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="pickles"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="necrologist"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="medicine-pocket"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="shamane"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support / Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>Any other healer.</p>
            </div>
            <h6 className="sustain">Sustain</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            This team focused around Melania’s Portray 2. Despite being at an
            Afflatus disadvantage against Gorgon, Melania’s consistently growing
            single-target damage output and ceiling of that damage output is so
            great that it practically overshadows the advantage bonus. Plus, due
            to how independent she is, she can be paired up with 3 powerful
            Supports and still cover the potential damage lost from not having a
            second Carry.
          </p>
          <ul>
            <li>
              Melania is practically made to function in Mane’s Bulletin;
              extended fights with a single-target are her specialty as she can
              comfortably stack [Fixed Plan] to ramp up her damage without
              worrying about killing her opponent too quickly. Do note that,
              despite Gorgon not having Moxie, Melania can still “steal” Moxie
              from it to charge her Ultimate.
            </li>
            <li>
              Pickles here, while not having to provide his AP-generosity in
              this setting, allows for an easy time setting up with his
              long-lasting +DMG Dealt buff from “Thus Spoke Pickles” and desire
              to AFK every other turn. It is also way easier for him to proc the
              condition for the extension of his buff due to having extra room
              to act and not act.
            </li>
            <li>
              Medicine Pocket is picked because their single-target +DMG Taken
              debuff synergizes well with Melania. In addition, since Melania is
              at an Afflatus disadvantage and will take additional damage from
              the boss, MP’s on-demand [Studiness] helps a lot to cover the
              outgoing damage from Gorgon.
            </li>
            <li>
              Tooth Fairy is another obvious Healer here. Absurd Crit-based
              debuffs, allow Melania to run a Crit Resonance build and still
              provide good damage. Solid healing with “Lullaby”, and decent
              single-target damage and some cleansing using “Song for the Bad
              Tooth”.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Kaalaa Baunaa</strong> - In the Mineral variety of this
              team, Kaalaa Baunaa is the obvious pick when it comes to
              single-target damage in a setting where AP is generally easier to
              come by. Her incredible damage ceiling, synergy with Pickles/Tooth
              Fairy and self-sustain all make her a great potential pick for
              Gorgon.
            </li>
            <li>
              <strong>Necrologist (P1-5)</strong> - Among the more surprising
              options, Necrologist finally finds her niche, and can replace both
              Pickles and a Healer in the Mane’s Bulletin environment. While
              usually AP-Greedy, this trait’s severity is dampened by the
              additional +1 AP from 4-man content. Keep in mind that if you use
              Pickles and Necrologist together, you will have to be more careful
              about the timing of their Ultimates, as they overlap.
            </li>
            <li>
              <strong>Shamane</strong> - A somewhat surprising but not unwelcome
              pick considering the usage of Tooth Fairy in this team, Shamane
              made his name as one of the most flexible debuffers in the game
              with a separate niche as a Crit-oriented semi-Carry. In case
              you’re using Necrologist over Pickles, consider Shamane in order
              to keep Melania/KB’s damage optimized while fitting neatly into
              the rest of the team.
            </li>
            <li>
              <strong>6</strong> - While not available until the second phase of
              the patch, 6 on his own is a staple Support especially for Mane’s
              Bulletin. He can be used in pretty much every team due to the
              versatility of his buffs, debuffs and access to semi-on-demand
              [Incantation Empowerment] for your Carry.
            </li>
            <li>
              <strong>Healers</strong> - As a rule of thumb, very few healers
              are restricted to particular teams, as their main role is to keep
              the team alive. It is also not uncommon for Endless teams to
              feature 2 healers, one occupying a Support slot.
            </li>
          </ul>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAvgust;

export const Head: React.FC = () => (
  <Seo
    title="Darkness of the Abyss | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Darkness of the Abyss Raid!"
    game="reverse"
  />
);
